<template>
  <DataTable
    :value="items"
    :filters="filters"
    removableSort
    :paginator="true"
    dataKey="id"
    :rows="10"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    :sortOrder="-1"
    sortField="created_at"
     :scrollable="true" scrollHeight="400px" 
  >
    <template #header>
      <div class="table-header">
        <h4>AreteIR Applications</h4>
        <div class="p-col-1-cust">
          <Button
            label="Clear All Filters"
            class="p-button-secondary iconmargin p-mr-2"
            @click="clearSearchHistory"
            icon="fas fa-times"
          />
          <Button
            v-if="isUserAdmin||isUserSuperAdmin"
            label="Create App"
            class="p-button-secondary iconmargin"
            @click="addApplication"
            icon="fas fa-plus"
          />
        </div>
      </div>
    </template>
    <template #empty> No apps found. </template>
    <div>
      <Column
        field="title"
        header="Name"
        headerClass="name"
        bodyClass="name"
        sortField="title"
        sortable>
        <template #filter>
          <InputText
            type="text"
            v-model="selectedname"
            class="p-column-filter"
            placeholder="Search by name"
            @keyup="onSearchApp()"
          />
        </template>
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else class="text-overflow" v-tooltip.top="`${slotProps.data.title}`">{{ slotProps.data.title }}</div>
        </template>
      </Column>
      <Column
        field="selectedgroups"
        header="Group"
        headerClass="group"
        bodyClass="group">
        <template #filter>
          <div class="p-grid p-m-0 p-p-0">
            <MultiSelect
              v-model="selectedgroups"
              :filter="true"
              :options="groupslist"
              @change="onGroupChange()"
              optionLabel="name"
              optionValue="name"
              appendTo="body"
              placeholder="Select Group"
              display="chip"
              class="p-column-filter p-col p-p-0 p-m-0">
              <template #option="slotProps">
                <span class="status customer-badge groupcustwidth">{{ slotProps.option.name }}</span>
              </template>
            </MultiSelect>
            <div class="p-col-1 p-p-0" v-if="ToArryLength(selectedgroups) > 0">
              <Chip class="p-col-chip">
                {{ ToArryLength(selectedgroups)}}
              </Chip>
            </div>
          </div>
        </template>
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>{{ ToList(slotProps.data.related_groups) }}</div>
        </template>
      </Column>
      <Column
        field="stage"
        header="Stage"
        :sortable="true"
        sortField="stage"
        filterField="stage"
        filterMatchMode="in"
        headerClass="stage"
        bodyClass="stage"
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>{{ slotProps.data.stage }}</div>
        </template>
        <template #filter>
          <div class="p-grid p-m-0 p-p-0">
            <MultiSelect
              v-model="selectedstages"
              :filter="true"
              :options="stagelist"
              @change="onStageChange()"
              placeholder="Select Stage"
              display="chip"
              appendTo="body"
              class="p-column-filter p-col p-p-0 p-m-0"
            >
              <template #option="slotProps">
                <span class="customer-badge status">{{
                  slotProps.option
                }}</span>
              </template>
            </MultiSelect>
            <div class="p-col-1 p-p-0" v-if="ToArryLength(selectedstages) > 0">
              <chip class="p-col-chip">
                {{ ToArryLength(selectedstages)}}
              </chip>
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="webURL"
        header="Live-URL"
        headerClass="url"
        bodyClass="url"
        sortable
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <a
              target="_blank"
              :href="slotProps.data.webURL"
              v-if="slotProps.data.webURL"
            >
              <span class="iconmargin"><i class="pi p-m-1 pi-link"></i>Link</span>
            </a>
          </div>
        </template>
      </Column>
      <Column
        field="qaURL"
        header="QA-URL"
        headerClass="url"
        bodyClass="url"
        sortable
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <a
              target="_blank"
              :href="slotProps.data.qaURL"
              v-if="slotProps.data.qaURL != null"
            >
              <span class="iconmargin"><i class="pi pi-link p-m-1"></i>Link</span>
            </a>
          </div>
        </template>
      </Column>
      <Column
        field="businessOwnerName"
        header="Business Owner Name"
        headerClass="businessownername"
        bodyClass="businessownername"
        sortField="businessOwnerName" 
        sortable
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <span v-if="slotProps.data.business_owner_name != null">{{
              slotProps.data["business_owner_name"]
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        field="app_owner1"
        header="App-Owner 1"
        headerClass="appowner"
        bodyClass="appowner"
        sortField="app_owner1.displayName" 
        sortable
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <span v-if="slotProps.data.app_owner1 != null">{{
              slotProps.data.app_owner1["displayName"]
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        field="app_owner2"
        header="App-Owner 2"
        headerClass="appowner"
        bodyClass="appowner"
        sortable
        sortField="app_owner2.displayName" 
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <span v-if="slotProps.data.app_owner2 != null">{{
              slotProps.data.app_owner2["displayName"]
            }}</span>
          </div>
        </template>
      </Column>
      <Column
        field="app_owner3"
        header="App-Owner 3"
        headerClass="appowner"
        bodyClass="appowner"
        sortField="app_owner3.displayName"
        sortable
      >
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <span v-if="slotProps.data.app_owner3 != null">{{
              slotProps.data.app_owner3["displayName"]
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Actions" headerClass="actions" bodyClass="actions">
        <template #body="slotProps">
          <div v-if="isloading"><Skeleton></Skeleton></div>
          <div v-else>
            <a :href="`/app/${slotProps.data.id}/details`">
              <span class="iconmargin p-mr-1"><i class="pi pi-eye p-m-1"></i>View</span>
            </a>
            <a :href="`/app/${slotProps.data.id}/edit`" v-if="isUserAdmin||isUserSuperAdmin">
              <span class="iconmargin"><i class="pi pi-pencil p-m-1"></i>Edit</span>
            </a>
          </div>
        </template>
      </Column>
    </div>
  </DataTable>
</template>
<script>
/* eslint-disable no-unused-vars */
export default {
  name: "appsDataTable",
  computed: {
    userInitials() {
      return this.$store.getters["login/getUserInitials"];
    },
    isUserAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserAdmin || false;
    },
    isUserSuperAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserSuperAdmin || false;
    }
  },
  mounted() {
    this.masterData();
  },
  components: {},
  methods: {
    addApplication() {
      this.$router.push("/createapp");
    },
    onSearchApp() {
      this.isloading = true;
      let selectedname = this.selectedname;
      sessionStorage.removeItem("selectedname");
      sessionStorage.setItem("selectedname", selectedname);
      this.$parent.searchApps(selectedname);
    },
    onGroupChange() {
      this.isloading = true;
      let selectedgroups = this.selectedgroups;
      sessionStorage.removeItem("selectedgroups");
      sessionStorage.setItem("selectedgroups", selectedgroups);
      this.$parent.groupChange(selectedgroups);
    },
    onStageChange() {
      this.isloading = true;
      let selectedstages = this.selectedstages;
      sessionStorage.removeItem("selectedstages");
      sessionStorage.setItem("selectedstages", selectedstages);
      this.$parent.stageChange(selectedstages);
    },
    clearSearchHistory() {
      sessionStorage.removeItem("selectedstages");
      sessionStorage.removeItem("selectedname");
      sessionStorage.removeItem("selectedgroups");
      this.masterData();
      this.$parent.getApps();
    },
    masterData(){
    let stagelist = this.stagelist;
    let groupslist = this.groupslist;
    let restoreFilters= {
        name:null,
        groups:null,
        stages:null,
      }
      // let selectedstages = stagelist.map((stage) => {
      //   return stage.name;
      // });
      // let selectedgroups = groupslist.map((stage) => {
      //   return stage.name;
      // });
      let restoreStages = sessionStorage.getItem("selectedstages");
      if (restoreStages) {
        this.selectedstages = restoreStages.split(',');
        restoreFilters.stages= this.selectedstages
        this.isrestore=true;
      // } else {
      //   this.selectedstages = selectedstages.sort(function (a, b) {
      //     return a.localeCompare(b);
      //   });
      }
      let restoreName = sessionStorage.getItem("selectedname");
      if (restoreName) {
        this.selectedname = restoreName;
         this.isrestore=true;
        restoreFilters.name= this.selectedname
      }
      let restoreGroups = sessionStorage.getItem("selectedgroups");
      if (restoreGroups) {
         this.isrestore=true;
        this.selectedgroups = restoreGroups.split(",");
        restoreFilters.groups=this.selectedgroups    
      // } else {
      //   this.selectedgroups = selectedgroups.sort(function (a, b) {
      //     return a.localeCompare(b);
      //   });
      } 
      if(this.isrestore){
       this.$parent.searchApplications(restoreFilters);
      }
   
    }
  },
  props: {
    items: { type: Array },
    groupslist: { type: Array },
    stagelist: { type: Array },
    isloading: { type: Boolean },
 
  },
  watch: {},
  data() {
    return {
      isrestore:false,
      filters: {},
      selectedstages: null,
      selectedgroups: null,
      selectedname: null,
    };
  },
};
</script>
<style scoped>
.iconmargin {
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer !important;
}
.p-column-filter {
  width: 90% !important;
}
.p-col-chip {
  margin-top: 5px !important;
  margin-left: 4px !important;
  background-color: #225083;
  color: white;
  font-weight: bold;
}
.groupcustwidth{
  width: 200px !important
}
</style>