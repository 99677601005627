<template>
  <div class="card">
    <div v-if="isloading">
    <LoginLoadingArete />
        <SkeletonTable ref="skeletonTable" :items="skeletonTable" />
    </div>
    <AppsDataTable ref="appsdatatable" 
     :items="applications"
     :groupslist="groups"
     :stagelist="stages"
     :isloading="isChildloading"
      v-else />
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import SkeletonTable from "@/components/Shared/SkeletonDataTable.vue";
import AppsDataTable from "@/components/Apps/AppsDataTable.vue";
export default {
  name: "aboutusComponent",
  created() {
    this.getApps();
  },
  components: { SkeletonTable, AppsDataTable },
  methods: {
    async getApps() {
      try {
        this.isloading=true;
        let result = await this.$store.dispatch("app/getApps");
        let applications = result.data;
        applications.forEach((element) => {
          element.webURL = this.getwebURL(element);
          element.qaURL = this.getqaURL(element);
        });
        this.groups = await this.$store.dispatch("group/getGroups");
        this.stages = this.$store.getters["app/groupAppStagesFromData"];
        this.applications = applications;
        this.isloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    searchApps(selectedname){
    let filters= {
        name:selectedname.toLowerCase(),
        groups:sessionStorage.getItem("selectedgroups")?sessionStorage.getItem("selectedgroups").split(','):null,
        stages:sessionStorage.getItem("selectedstages")?sessionStorage.getItem("selectedstages").split(','):null,
      }
    this.searchApplications(filters);
    // let apps = this.$store.getters["app/getApps"];
    // let name = selectedname.toLowerCase();
    //   setTimeout(()=>{
    //   let filteredApps= apps.filter((item)=>{
    //      return item.title.toLowerCase().indexOf(name)>-1;
    //   });
    //   this.applications=filteredApps;
    //   this.isChildloading=false;
    //   },2000)   
    },
    groupChange(selectedgroups){
    let filters= {
        name:sessionStorage.getItem("selectedname")?.toLowerCase(),
        groups:selectedgroups,
        stages:sessionStorage.getItem("selectedstages")?sessionStorage.getItem("selectedstages").split(','):null,
      }
    this.searchApplications(filters);
      // let groups = await this.$store.dispatch("group/getGroups");
      // let apps = this.$store.getters["app/getApps"];
      // if(groups.length != selectedgroups.length){
      //   this.isChildloading=true;
      //   setTimeout(()=>{
      //   const filteredApps = apps.filter((apps) => {
      //     return selectedgroups.some((sapps) => {
      //       return apps.related_groups.includes(sapps)
      //     }); 
      //   });
      //   this.applications=filteredApps;
      //   this.isChildloading=false;
      //   },2000)         
      // }
      // else{
      //   this.applications=apps;
      // } 
     },
    stageChange(selectedstages){
      let filters= {
        name:sessionStorage.getItem("selectedname")?.toLowerCase(),
        groups:sessionStorage.getItem("selectedgroups")?sessionStorage.getItem("selectedgroups").split(','):null,
        stages:selectedstages,
      }
         this.searchApplications(filters);
      // this.isChildloading=true;
      // let apps = this.$store.getters["app/getApps"];
      // setTimeout(()=>{
      // const filteredApps = apps.filter((apps) => {
      //   return selectedstages.some((sapps) => {
      //       return sapps.toLowerCase() === apps.stage.toLowerCase();
      //   }); 
      // });
      // this.applications=filteredApps;
      // this.isChildloading=false;
      // },2000)  
     },
     searchApplications(restoreFilters){
      this.isChildloading=true;
       let filters = {...restoreFilters};
       let apps = this.$store.getters["app/getApps"];
       let filteredApps= apps;
       if(filters.name !=null){
        let name = filters.name.toLowerCase();
        filteredApps= filteredApps.filter((item)=>{
          return item.title.toLowerCase().indexOf(name)>-1;
        });
       }
       if(filters.stages !=null && filters.stages.length){
        let stages= filters.stages;
        filteredApps=filteredApps.length?filteredApps:apps;
        filteredApps = filteredApps.filter((apps) => {
          return stages.some((sapps) => {
              return sapps.toLowerCase()=='empty'? apps.stage.length=="" || sapps.toLowerCase()===apps.stage.toLowerCase():sapps.toLowerCase()===apps.stage.toLowerCase();
          }); 
        });
       }
       if(filters.groups!=null && filters.groups.length){
          let groups= filters.groups;
          filteredApps=filteredApps.length?filteredApps:apps;
          filteredApps = filteredApps.filter((apps) => {
          return groups.some((sapps) => {
            return sapps.toLowerCase()=='empty'? apps.related_groups.length==0 ||apps.related_groups.includes(sapps) :apps.related_groups.includes(sapps)
          });  
        });       
       }
      this.applications=filteredApps;
      setTimeout(() => {
        this.isChildloading=false;
      }, 500);
     }
  },
  props: {},
  watch: {},
  data() {
    return {
      applications: null,
      isloading: true,
      stages: null,
      groups: null,
      isChildloading:false,
      skeletonTable:{
      columns:[
        {field: 'code', header: 'Name'},
        {field: 'category', header: 'Group'},
        {field: 'stage', header: 'Stage'},
        {field: 'webURL', header: 'Live-URL'},
        {field: 'qaURL', header: 'QA-URL'},
        {field: 'appowner1', header: 'App-Owner 1'},
        {field: 'appowner2', header: 'App-Owner 2'},
        {field: 'appowner3', header: 'App-Owner 3'},
        {field: 'actions', header: 'Actions'},
        ],
      isRequiredHeader:true,
    },
    };
  },
};
</script>
<style scoped>
</style>